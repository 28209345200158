import React, { useState } from 'react';
import { useQueryClient, useMutation } from '@tanstack/react-query';

// MATERIAL
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';

// ICONS

// COMPONENTS
import CardLayout from '../../../components/CardLayout';
import { deviceIcon } from '../../../components/CardIcons';
// import { LogDialog } from './LogDialog';
import LogDialog from './LogDialog';

// APIS
import { deleteKeyQuery } from '../apis/deleteKeyQuery';

export default function CardKey({ keyObject, showKeylocation, updateReservation, setKeyToEdit, entityId }) {

  const [anchorMenu, setAnchorMenu] = useState(null);
  const [openDialog, setOpenDialog] = useState(null);

  const handleOpenMenu = (event) => {
    setAnchorMenu(event.currentTarget);
  };

  const queryClient = useQueryClient();

  const deleteKeyMutation = useMutation(deleteKeyQuery, {
    onSuccess: () => {
      // Invalidate and refetch
      queryClient.invalidateQueries(['keysboards', entityId]);
      handleClose();
    },
    onError: (error) => {
      console.log(error);
      enqueueSnackbar('Failed to create key', { variant: 'error' });
    },
  });

  const commonMenuItems =
    keyObject.status === null ? ['Allocate', 'Logs', 'Edit', 'Delete'] : ['Locate', 'Logs', 'Edit', 'Delete'];

  const handleCloseMenu = async (menuItem) => {
    switch (menuItem) {
      case 'Locate':
        setAnchorMenu(null);
        showKeylocation(keyObject.id);
        break;
      case 'Allocate':
        setAnchorMenu(null);
        updateReservation(keyObject);
        break;
      case 'Logs':
        setAnchorMenu(null);
        setOpenDialog('LOG_DIALOG');
        break;
      case 'Edit':
        setAnchorMenu(null);
        setKeyToEdit(keyObject);
        // setOpenDialog('EDIT_DIALOG');
        break;
      case 'Delete':
        deleteKeyMutation.mutate({ keyId: keyObject.id });
        // console.log('Delete Key', keyObject.id);
        // console.log(menuItem, device.id, device.name, groupId, groups);
        setAnchorMenu(null);
        break;

      default:
        // console.log(menuItem);
        setAnchorMenu(null);
        break;
    }
  };

  const keyMenu = () => {
    return (
      <>
        <Menu
          sx={{ mt: '25px' }}
          id='menu-appbar'
          anchorEl={anchorMenu}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          keepMounted
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          open={Boolean(anchorMenu)}
          onClose={handleCloseMenu}
        >
          {commonMenuItems.map((menuItem) => (
            <MenuItem key={menuItem} onClick={() => handleCloseMenu(menuItem)}>
              <Typography textAlign='center'>{menuItem}</Typography>
            </MenuItem>
          ))}
        </Menu>
      </>
    );
  };

  return (
    <>
      <CardLayout

        data={{ 'key-id': keyObject.unique_id, 'key-name': keyObject.name }}
        onClickFunction={null}
        icon={deviceIcon('Key Store Key', true, false, true)}
        active={
          keyObject.status === null ? (keyObject.collected_by === null ? null : false) : true
        }
        text={keyObject.name}
        caption={keyObject.collected_by === null ? keyObject.address : `${keyObject.collected_by} (${keyObject.phone})`}
        handleOpenMenu={handleOpenMenu}
        menu={keyMenu()}
      />
      {openDialog === 'LOG_DIALOG' && (<LogDialog keyItem={keyObject} handleClose={() => setOpenDialog(null)} />)}
    </>
  );
}
