/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import theme from '../../styles/materialTheme';
import { useQuery } from '@tanstack/react-query';

// Material
import Box from '@mui/material/Box';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';

// COMPONENTS
import DeviceDialog from './dialogs/DeviceDialog';
import GroupDialog from './dialogs/GroupDialog';
import ShareDialog from './dialogs/ShareDialog';
import DeviceTitleBlock from './components/DeviceTitleBlock';
import CardDevice from './components/CardDevice';
import { CardGrid } from '../../components/CardGrid';
// import CardKeyStore from './components/CardKeyStore';

// ICONS
import FolderOpenIcon from '@mui/icons-material/FolderOpen';
import FolderIcon from '@mui/icons-material/Folder';
import DeviceHubIcon from '@mui/icons-material/DeviceHub';

// FUNCTIONS
// import { processStatusMqttMessage, subscribeToTopic } from '../../functions/handleMQTT';
import { Typography } from '@mui/material';

import { processStatusMqttMessage } from './functions/processMQTT';
import useMQTT from '../../hooks/useMQTT';

let group;

function DevicesTree({ entity, searchItem, currentView }) {
  const { data: deviceList } = useQuery({
    queryKey: ['devices', entity.id],
  });

  const [openDialog, setOpenDialog] = useState(null);
  const [renderDevices, setRenderDevices] = useState(deviceList.devices);
  const [expandedGroups, setExpandedGroups] = useState(
    new Set([deviceList.groups.find((el) => el.parentId === null).groupId])
  );
  const [deviceStatus, setDeviceStatus] = useState({});

  useMQTT(`${entity.uuid.substring(0, 8)}/#`, (data) =>
    processStatusMqttMessage(data, setDeviceStatus)
  );
  // useEffect(() => {
  //     const newSubscription = subscribeToTopic(`${entity.uuid.substring(0, 8)}/#`, (data) =>
  //     processStatusMqttMessage(data, setDeviceStatus)
  //     );

  //   return () => {
  //       console.log('Unsubscribing to Devices MQTT');
  //       newSubscription.unsubscribe();
  //   };
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  const refreshEntities = () => {
    setRenderDevices(deviceList.devices);
    // setExpandedGroups(new Set([deviceList.groups.find((el) => el.parentId === null).groupId]));
  };

  useEffect(() => {
    refreshEntities();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deviceList.devices]);

  useEffect(() => {
    if (searchItem.length > 0) {
      const devicesFound = deviceList.devices.filter(
        (el) => el.name && el.name.toLowerCase().includes(searchItem.toLowerCase())
      );

      // Always show the root group
      const expandedGroupsForFoundDevices = new Set([
        deviceList.groups.find((el) => el.parentId === null).groupId,
      ]);

      const expandGroups = (groupIdToFind) => {
        const foundParent = deviceList.groups.find((el) => el.groupId === groupIdToFind);
        console.log('foundParent', foundParent);

        if (foundParent) {
          expandedGroupsForFoundDevices.add(foundParent.groupId);
          if (foundParent.parentId !== null) {
            expandGroups(foundParent.parentId);
          }
        }
      };

      devicesFound.forEach((device) => {
        const groupsOfFoundDevices = deviceList.groups.filter((el) =>
          new Set([...el.deviceIds]).has(device.id)
        );

        groupsOfFoundDevices.forEach((el) => {
          expandedGroupsForFoundDevices.add(el.groupId);
          expandGroups(el.parentId);
        });
      });

      setExpandedGroups(expandedGroupsForFoundDevices);
      setRenderDevices(devicesFound);
    } else {
      refreshEntities();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchItem]);

  const dialogToOpen = (text, _group) => {
    group = _group;
    setExpandedGroups((prev) => new Set(prev.add(group.groupId)));
    setOpenDialog(text);
    // console.log(text, _group);
  };

  const expandDeviceGroup = (deviceGroup) => {
    if (deviceGroup.parentId !== null) {
      if (expandedGroups.has(deviceGroup.groupId)) {
        setExpandedGroups(
          (prev) => new Set([...prev].filter((x) => x !== deviceGroup.groupId))
        );
      } else {
        setExpandedGroups((prev) => new Set(prev.add(deviceGroup.groupId)));
      }
    }
  };

  const handleDialogClose = (event, reason) => {
    if (reason && reason === 'backdropClick') return;
    setOpenDialog(null);
  };

  const deviceFolderIcon = (deviceGroup) => {
    if (deviceGroup.parentId === null) return <DeviceHubIcon />;
    if (expandedGroups.has(deviceGroup.groupId)) return <FolderOpenIcon />;
    return <FolderIcon />;
  };

  const DeviceGrid = (deviceArray, groupId) =>
    CardGrid(
      deviceArray.map((device) => (
        <CardDevice
          key={device.id}
          device={{ ...device, ...deviceStatus[device.uuid.split('-')[0]] }}
          groupId={groupId}
          entity={entity}
          currentView={currentView}
          groupToExpand={(id) => setExpandedGroups((prev) => new Set(prev.add(id)))}
        />
      ))
    );

  // const renderKeyStores = () => {
  //   return (
  //     <Paper
  //       variant='outlined'
  //       sx={{
  //         padding: '5px',
  //         marginLeft: '5px',
  //         marginBottom: '5px',
  //         marginTop: '10px',
  //         backgroundColor: theme.palette.secondary.main,
  //         color: theme.palette.info.main,
  //       }}
  //     >
  //       <Box display='flex' alignItems='center' flexWrap='wrap'>
  //         <DeviceTitleBlock
  //           icon={deviceFolderIcon('Key Stores')}
  //           title={'Key Stores'}
  //           expanded={null}
  //         />
  //       </Box>
  //       {deviceList.keyStores.map((keyStore) => (
  //         <CardKeyStore
  //           key={keyStore.groupId}
  //           device={keyStore}
  //           groups={deviceList.groups}
  //           entity={entity}
  //           groupToExpand={(id) => setExpandedGroups((prev) => new Set(prev.add(id)))}
  //         />
  //       ))}
  //     </Paper>
  //   );
  // };

  const renderGroup = (deviceGroup, depth) => {
    const devicesInGroup = renderDevices.filter((el) =>
      new Set([...deviceGroup.deviceIds]).has(el.id)
    );
    const childGroups = deviceList.groups.filter(
      (el) => el.parentId === deviceGroup.groupId && el.groupTypeId !== 4
    );

    // console.log(deviceGroup);

    return (
      <Paper
        data-tg-tour={'View, activate, update and add new devices in this section.'}
        variant='outlined'
        key={deviceGroup.groupId}
        sx={{
          padding: '5px',
          marginLeft: '5px',
          marginBottom: '5px',
          marginTop: '10px',
          backgroundColor:
            depth % 2 === 0 ? theme.palette.secondary.main : theme.palette.info.main,
          color: depth % 2 === 0 ? theme.palette.info.main : theme.palette.secondary.main,
        }}
      >
        <Box display='flex' alignItems='center' flexWrap='wrap'>
          <DeviceTitleBlock
            onClickFunction={() => expandDeviceGroup(deviceGroup)}
            icon={deviceFolderIcon(deviceGroup)}
            title={deviceGroup.groupName}
            menuVisible={deviceGroup.groupTypeId <= 4}
            addDeviceVisible={entity.userTypeId < 4}
            dialogToOpen={(text) =>
              dialogToOpen(text, {
                ...deviceGroup,
                okToDelete: devicesInGroup.length === 0 && childGroups.length === 0,
              })
            }
            iconColor={
              depth % 2 === 0 ? theme.palette.info.main : theme.palette.secondary.main
            }
          />
        </Box>



        {currentView === 'Group View' ? (
          <>
            {(deviceGroup.groupName === 'Devices' && renderDevices.length === 0) ? (
              <Typography data-tg-tour={"No devices currently found! Try using the ⋯ menu on the right hand side to add a new device."}>No devices found</Typography>
            ) : (
              expandedGroups.has(deviceGroup.groupId) && devicesInGroup.length > 0 && DeviceGrid(devicesInGroup, deviceGroup.groupId)
            )}
            {expandedGroups.has(deviceGroup.groupId) && childGroups.length > 0
              ? childGroups.map((childDeviceGroup, index) => (
                <Grow
                  key={childDeviceGroup.groupId}
                  in={expandedGroups.has(deviceGroup.groupId)}
                  // eslint-disable-next-line react/jsx-props-no-spreading
                  {...(expandedGroups.has(deviceGroup.groupId)
                    ? { timeout: 500 * index }
                    : {})}
                >
                  {renderGroup(childDeviceGroup, depth + 1)}
                </Grow>
              ))
              : null}
          </>
        ) : renderDevices.length > 0 ? (
          DeviceGrid(renderDevices, deviceGroup.groupId)
        ) : (
          <Typography data-tg-tour={"No devices currently found! Try using the ⋯ menu on the right hand side to add a new device."}>No devices found</Typography>
        )}
      </Paper>
    );
  };

  return (
    <>
      {openDialog === 'ADD_GROUP_DIALOG' && (
        <GroupDialog
          action={'ADD'}
          parentGroupId={group.groupId}
          parentGroupName={group.groupName}
          entityId={entity.id}
          okToDelete={false}
          handleClose={handleDialogClose}
        />
      )}
      {openDialog === 'EDIT_GROUP_DIALOG' && (
        <GroupDialog
          action={'EDIT'}
          parentGroupId={group.groupId}
          parentGroupName={group.groupName}
          entityId={entity.id}
          okToDelete={group.okToDelete}
          handleClose={handleDialogClose}
        />
      )}
      {openDialog === 'ADD_DEVICE_DIALOG' && (
        <DeviceDialog
          title='Add'
          deviceId={null}
          entity={entity}
          handleClose={handleDialogClose}
          groupId={group.groupId}
        />
      )}
      {openDialog === 'SHARE_DIALOG' && (
        <ShareDialog
          deviceName={group.groupName}
          deviceID={null}
          groupId={group.groupId}
          handleClose={handleDialogClose}
        />
      )}

      {deviceList.groups?.length > 0 ? (
        renderGroup(
          deviceList.groups.find((el) => el.parentId === null),
          0
        )
      ) : (
        <p>No devices found</p>
      )}
      {/* {deviceList.keyStores?.length > 0 &&
        currentView === 'Group View' &&
        renderKeyStores(deviceList.keyStores)} */}
    </>
  );
}

export default DevicesTree;
