import { apiGet, apiPost } from "../utils/api";



const fetchDevicesMeta = async () => {
  const result = await apiGet('devices/get-devices-meta');
  // console.log(result);
  return result;
};

const fetchDevices = async (entityId) => {
  if(isNaN(parseInt(entityId))) return [];
  const postBody = { entityId: entityId };
  const result = await apiGet('devices/get-entity-devices', postBody);
  // console.log(result);
  return result;
};

const fetchDeviceLogs = async (deviceId) => {
  if(isNaN(parseInt(deviceId))) return [];
  const postBody = { id: deviceId };
  const result = await apiGet('devices/get-device-logs', postBody);
  return result;
};

const fetchDeviceAccessKeys = async (postBody) => {
  // if(isNaN(parseInt(deviceId))) return [];
  // const postBody = { deviceId: deviceId };
  console.log(postBody);
  const result = await apiGet('devices/get-device-keys', postBody);
  console.log(result);
  return result;
};

const createDeviceAccessKey = async (postBody) => {
  const res =  await apiPost('devices/create-device-key', postBody);
  return res;
};

const deleteDeviceAccessKey = async (postBody) => {
  const res =  await apiPost('devices/delete-device-key', postBody);
  return res;
};

const restoreDevice = async (postBody) => {
  const res =  await apiPost('devices/restore-device', postBody);
  return res;
};
const modifyDeviceGroups = async (postBody) => {
  const res = await apiPost(`devices/${postBody.endpoint}`, postBody.body);
  return res;
};


export { modifyDeviceGroups, fetchDevicesMeta, fetchDevices, fetchDeviceLogs, fetchDeviceAccessKeys, createDeviceAccessKey, deleteDeviceAccessKey, restoreDevice };
