import React, { useState } from 'react';

// MUI
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import theme from '../../../styles/materialTheme';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';

// ICONS
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';
// import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
// import EditIcon from '@mui/icons-material/Edit';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';

// HOOKS
// import useWindowDimensions from '../../../hooks/useWindowDimensions';

function DeviceTitleBlock(_props) {
  const { onClickFunction, icon, title, menuVisible, addDeviceVisible, dialogToOpen, iconColor } = _props;
  // const activeGroup = false; // TODO: Add this into props
  // const { width: displayWidth, divideWidth } = useWindowDimensions();

  const [anchorDeviceMenu, setAnchorDeviceMenu] = useState(null);
  const handleOpenDeviceMenu = (event) => {
    setAnchorDeviceMenu(event.currentTarget);
  };

  const handleCloseDeviceMenu = async (menuItem) => {
    switch (menuItem) {
      case 'Add Device':
        setAnchorDeviceMenu(null);
        dialogToOpen('ADD_DEVICE_DIALOG');
        break;
      case 'Add Group':
        setAnchorDeviceMenu(null);
        dialogToOpen('ADD_GROUP_DIALOG');
        break;
      case 'Edit Group':
        setAnchorDeviceMenu(null);
        dialogToOpen('EDIT_GROUP_DIALOG');
        break;
      case 'Share Group':
        setAnchorDeviceMenu(null);
        dialogToOpen('SHARE_DIALOG');
        break;
      default:
        setAnchorDeviceMenu(null);
        break;
    }
  };

  const groupMenu = () => {
    return (
      <Menu
        sx={{ mt: '25px' }}
        id='menu-appbar'
        anchorEl={anchorDeviceMenu}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        keepMounted
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
        open={Boolean(anchorDeviceMenu)}
        onClose={handleCloseDeviceMenu}
      >
          {addDeviceVisible && <MenuItem onClick={() => handleCloseDeviceMenu('Add Device')}>
            <Typography textAlign='center'>Add Device</Typography>
          </MenuItem>}

          <MenuItem onClick={() => handleCloseDeviceMenu('Add Group')}>
            <Typography textAlign='center'>Add Group</Typography>
          </MenuItem>

          <MenuItem onClick={() => handleCloseDeviceMenu('Edit Group')}>
            <Typography textAlign='center'>Edit Group</Typography>
          </MenuItem>

          <MenuItem onClick={() => handleCloseDeviceMenu('Share Group')}>
            <Typography textAlign='center'>Share Group</Typography>
          </MenuItem>

      </Menu>
    );
  };

  return (
    <Box
      display='flex'
      alignItems='center'
      width={'100%'}
    >
      <Box
        sx={{
          padding: 1,
          color: theme.palette.primary.main,
        }}
        onClick={onClickFunction}
      >
        {icon}
      </Box>
      <Box
        flexGrow={1}
        sx={{
          pt: 2,
          pb: 2,
        }}
        onClick={onClickFunction}
      >
        <Typography>{title}</Typography>
      </Box>
      {menuVisible && (
        <Box p={1}>
          <Tooltip title='Open settings'>
            <IconButton onClick={handleOpenDeviceMenu} sx={{ p: 1 }}>
              <MoreHorizIcon sx={{color: iconColor}}/>
            </IconButton>
          </Tooltip>
          {anchorDeviceMenu && groupMenu()}
        </Box>
      )}
    </Box>
  );
}

export default DeviceTitleBlock;
